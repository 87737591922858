import { withFronteggApp } from '@frontegg/nextjs/pages';
import '@mantine/carousel/styles.css';
import '@mantine/charts/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import '@mantine/nprogress/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  Analytics,
  AppLayoutContextProvider,
  IntercomWidget,
  LoadingOverlayProvider,
  SupportInformation,
} from '@zorro/shared/ui';
import { useAppProgressBar } from '@zorro/shared/utils';
import { NavigationProgress, Theme } from '@zorro/zorro-ui-design';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useState } from 'react';

import '../styles/global.css';

interface MurrietaAppProps extends AppProps {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: AppProps['Component'];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function MurrietaApp({ Component, pageProps }: MurrietaAppProps) {
  useAppProgressBar();

  // eslint-disable-next-line react/hook-use-state
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            staleTime: 60_000,
            retry: false,
          },
        },
      })
  );

  return (
    <>
      <Head>
        <title>Zorro Ops</title>
      </Head>

      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={Theme}>
          <LoadingOverlayProvider>
            <IntercomWidget />
            <SupportInformation />
            <Analytics applicationName="murrieta" />
            <NavigationProgress />
            <Notifications position="top-right" />
            <AppLayoutContextProvider>
              <Component {...pageProps} />
            </AppLayoutContextProvider>
          </LoadingOverlayProvider>
        </MantineProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default withFronteggApp(MurrietaApp, {
  hostedLoginBox: true,
  lazyLoadAdminPortal: true,
  authOptions: {
    keepSessionAlive: true,
  },
  themeOptions: {
    adminPortal: {
      pages: {
        profile: {
          fieldsProperties: {
            address: {
              appearance: 'hidden',
            },
            jobTitle: {
              appearance: 'hidden',
            },
          },
        },
        privacy: {
          fieldsProperties: {
            loginSessions: {
              appearance: 'hidden',
            },
          },
        },
      },
    },
  },
});
